
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCcyRhqULb0xmHOkBAO5rQtrpk83247NtE",
  authDomain: "dealinghand.firebaseapp.com",
  projectId: "dealinghand",
  storageBucket: "dealinghand.appspot.com",
  messagingSenderId: "750885970123",
  appId: "1:750885970123:web:5e308372648822665abb4e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
