import React from 'react'
import "./pricing.css"
import MetaData from '../layout/MetaData'
import { Link } from 'react-router-dom'

const Pricing = () => {
  return (<>
  <MetaData title="Our pricing Plans" />
    <div className='mainprice'>
    <div className="content">
        <h2 className='contenth1'> Our Pricing</h2>
        <p>
        <li>   If you are a business user , you can save the money and time . 
        Immediately sale your product abg grow your business.</li>
     
        </p>
        <p>
        <li>  Select the Featured Ad or Business ad package.
	Select the package of your choice.
	Make payment</li>
     
        </p>
        <p>
        <li>  Paid ads will be available within 6 hours after payment. </li>
     
        </p>
        <p>
        <li> For guidnace/assistance,contact us via live chat. </li>
     
        </p>
      
 

	




  

        
        </div>
       

      
    <div className="columns">
  <ul className="price">
    <li className="header">Basic</li>
    <li className="grey">Free</li>
    <li>15 Days</li>
    
    <li className="grey"><Link to="/register" className="button">Sign Up</Link></li>
  </ul>
</div>

<div className="columns">
  <ul className="price">
    <li className="header" style={{backgroundColor:"#3AB0FF"}}>Featured</li>
    <li className="grey">$5</li>
    <li>30 Days</li>
    
    <li className="grey"><Link to="/register" className="button">Sign Up</Link></li>
  </ul>
</div>

<div className="columns">
  <ul className="price">
    <li className="header">Business</li>
    <li className="grey">$20</li>
    <li>3 Months</li>
   
    <li className="grey"><Link to="/register" className="button">Sign Up</Link></li>
  </ul>
</div>

    </div>
    </>
  )
}

export default Pricing    
