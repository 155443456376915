import React, {useState, useEffect, useRef} from 'react'
import { useAlert } from "react-alert"
import axios from "axios"
import himage from "../../Assests/dealinghand.svg"
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import {FaSearch} from 'react-icons/fa'
import {IoIosArrowDown} from 'react-icons/io'
import {ImCross} from 'react-icons/im'
import {Mobile,Vehicles,Property,Propertyforrent,Animals,Kids,Electronicsandhomeappliances,fashionandbeauty,furniture,
  jobs,services,Sportsandfitness,Books} from "./category.js"
import Pagination from "react-js-pagination"
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Autoplay } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "swiper/css/effect-flip";

import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {useHistory,useLocation}  from  "react-router-dom"
import    "./hero.css";
import   "./fheader.css"
import  Product  from '../product/Product';
import { JellyfishSpinner } from "react-spinners-kit";
import MetaData from '../layout/MetaData';
const Hero = () => {
  const {search} =useLocation()
  const searchparams=new URLSearchParams(search)
  const categoryname=searchparams.get("advertisement_category") || ""
  console.log(categoryname);
  const history =useHistory()
  
  const [currentPage, setCurrentPage] = useState(1);
  const inputel = useRef(null);
  const [category, setCategory] = useState(categoryname);
  const [city, setCity] = useState(null);
  const [country,setCountry] = useState(null);
  const [provience, setProvience] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [cat,setCat]= useState(false);
  const [loc,setLoc]= useState(false);
  const[clist,setClist]=useState(null);
  const  [data,setData]=useState(null);
  const [pl,setPl]=useState(null);
  const [cll,setCll]=useState(null);
  const [statel,setStatel]=useState(null);
  function togglelo() {
   if(cat===true)
   { setCat(!cat);
    setLoc(!loc);}
    else{
      setLoc(!loc);
      
    }
  }
  function toggleca() {
   if(loc===true)
  { setLoc(!loc);
    setCat(!cat);}
    else{
      setCat(!cat);
      
    }

    // function cross() {
    //   setCat(false);
    //   setLoc(false);
    // }
    
  }
  function addcat(e){
 
    setCategory(e.target.innerText);
    setCurrentPage(1);
    setCat(false);

  }
 
  function lochead(){
    setCat(false);
    setLoc(false);
  }
  function formevent(e){
    e.preventDefault();
   
    setKeyword(inputel.current.value);
   

  }
  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };
  
  const dispatch = useDispatch();
  const alert = useAlert();

  const {  
    products,
    loading,
    error,
    // productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);

  let count = filteredProductsCount;
  useEffect(() => {
   
    //  setClist(Country.getAllCountries());
    
   
    if (error) {
     
      alert.error(error);
      dispatch(clearErrors());
    }
    

   if(category)
    history.push(`/?advertisement_category=${category.toLowerCase()}`)
    else
    history.push(`/`)
    dispatch(getProduct(keyword, currentPage, category, city,provience,country));
   
   
  }, [dispatch, keyword, currentPage ,provience,city, category,alert, error,country,history]);

  useEffect(() => {
  


axios.get("https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json")
.then((res)=>{setData(res.data)
  const cl=[...new Set(res.data.map(item => item.country))];

  cl.sort();
  setClist(cl);
  
})
.catch(error => alert.error(error));

  },[alert]);

  
  
  return (<>
  <MetaData title="Advertisement A way to promote  business with Dealinghand"  />
     <div className='fcontainer'>
      <div className="location" onClick={togglelo}>
      <div className='catm' id="location">  
               Select location
 
</div>
<div className='sbtn'>
<IoIosArrowDown className='searchb'/>
</div>
</div>
      <div className="search"><form onSubmit={formevent} ><input type="text" ref={inputel} placeholder='Seach Here' onChange={e=>e.target.value} />
      <button type='submit' className="sbtn"><FaSearch className="searchb"/></button></form></div>
      <div className="category"  onClick={toggleca}>
      <div className='catm'  id="location">
 Select Category
</div>
<div className='sbtn'>
<IoIosArrowDown className='searchb'/>
</div>
</div>

    </div>
    {cat &&  <div className='maincat' >
      <div className="maincategory" onClick={()=>{setCategory(""); setCat(false)}}>All</div>
      <div className="maincategory" onClick={()=>{setCategory("Mobile"); setCat(false)}}>Mobile </div>
     {Mobile.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("Vehicles"); setCat(false)}}> Vehicles</div>
     { Vehicles.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("Property for Sale"); setCat(false)}}>Property for Sale</div>
     { Property.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("Property for Rent"); setCat(false)}}>Property for Rent</div>
     { Propertyforrent.map((item,index)=>{
       return <div className='catitem'onClick={(e)=>{ 
         
        setCategory("R"+e.target.innerText);
        setCurrentPage(1);
        setCat(false);}} key={index} >{item}</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("Animals"); setCat(false)}}>Animals</div>
     { Animals.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("Kids"); setCat(false)}}>Kids</div>
     { Kids.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
    
     <div className="maincategory" onClick={()=>{setCategory("Fashion & Beauty"); setCat(false)}}>Fashion & Beauty</div>
     { fashionandbeauty.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("jobs"); setCat(false)}}>jobs</div>
     { jobs.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item} jobs</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("Furniture"); setCat(false)}}>Furniture</div>
     { furniture.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
     
     <div className="maincategory" onClick={()=>{setCategory("Sports and fitness"); setCat(false)}}>Sports & fitness</div>
     { Sportsandfitness.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("Books,Sports & Hobbies"); setCat(false)}}>Books,Sports & Hobbies</div>
     {Books.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
     <div className="maincategory" onClick={()=>{setCategory("Services"); setCat(false)}}>Services</div>
     { services.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
      <div className="maincategory" onClick={()=>{setCategory("Electronics & Home Appliances"); setCat(false)}}>Electronics & Home Appliances</div>
     { Electronicsandhomeappliances.map((item,index)=>{
       return <div className='catitem'onClick={addcat} key={index} >{item}</div>
     })}
      <div className="maincategory" onClick={()=>{setCategory("Others"); setCat(false)}}>Others</div>
      <div className='crositem' onClick={lochead} > <ImCross/></div>
      
    </div>}
    {loc &&  <div className='loc' >
      <h1>Select Location</h1>
     <div className="locdiv">
     <select  name="" id=""  onChange={(e)=>{
       e.target.value==="null"?setCountry(null):setCountry(e.target.value);
       setCity(null);
       setProvience(null);
       setCurrentPage(1);
      let  statesz=data.filter(item=>item.country===e.target.value);
       let states1ists= [...new Set(statesz.map(item=>item.subcountry))];
       setStatel(statesz);
        let states=states1ists.sort();
       setPl(states);
      
   
       
         }}>
              <option selected={(country==="null")?true:false} value="null">All over the World</option>
              {clist.map((item,index)=>{
                return <option key={index} selected={(country===item)?true:false} value={item}>{item}</option>
              })}
         </select>
   {country&&  <select name="" id=""  onChange={(e)=>{
       e.target.value==="null"?setProvience(null):setProvience(e.target.value);

       setCity(null);
       setCurrentPage(1);
       let cities=statel.filter(item=>item.subcountry===e.target.value);
       setCll(cities);
      //   let cities1= [... new Set(cities.map(item=>item.city))];
      
       
         }}>
        <option selected={(provience==="null")?true:false} value="null">All over the {country}</option>
        {pl.map((item,index)=>{
          return <option key={index} selected={(provience===item)?true:false} value={item}>{item}</option>
        })}
        
      </select>}
     {provience&& <select name="" id="" onChange={(e)=>{
       e.target.value==="null"?setCity(null):setCity(e.target.value);
     
       setCurrentPage(1);
     
       
         }}>
           <option selected={(city==="null")?true:false} value="null">All over the {provience}</option>
        {cll.map((item,index)=>{
          return <option key={index} selected={(city===item.name)?true:false} value={item.name}>{item.name}</option>
        })}




      
         
  
      </select>}
     </div>
     <div className='crositem' onClick={lochead} >  <ImCross/></div>
      
    </div>}
    <div className="adhead1">
          {country&&<div className="category">country:{country}</div>}
          {category&&<div className="category">Category:{category}</div>}
        {  provience&&<div className="category">Provience:{provience}</div>}
        {  city&&<div className="category">City:{city}</div>}
        {  keyword&&<div className="category">Search:{keyword}</div>}
            </div>

 {/* <div className="heroslide">
  <h1>DealingHand</h1>

 <h2>Advertisement is a most effective way to promote a business. As a businessman you can use it and get the best results within a short period</h2>
            
        
     
      </div> */}
      <div className='himage'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
       
       
        modules={[Autoplay,]}
        className="mySwiper"
      >
        <SwiperSlide> <img src={himage} alt='dealinghand'/></SwiperSlide>
        <SwiperSlide> <img src={himage} alt='dealinghand'/></SwiperSlide>
        <SwiperSlide> <img src={himage} alt='dealinghand'/></SwiperSlide>
       
      </Swiper>
      </div>
    
      
    
    <div className='hero' onClick={lochead}> 
    
    { loading ?<div className='noproduct'><JellyfishSpinner color='pink'/></div>:
    (products&&products.length===0?<h1 className='noproduct' style={{color:"black"}}>No Product found for your Search</h1> :
products&&products.map((product, index) => <Product key={index} product={product}/>
    )
     )
     }  
   
     
     
    
   </div>    
   
          {products&&resultPerPage < count && (   
            <div className="paginationBox">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={count}
                onChange={setCurrentPageNo}
                nextPageText="Next"
                prevPageText="Prev"
                firstPageText="1st"
                lastPageText="Last"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />
            </div>
          )}
        
    </>
  )
}

export default Hero