import React, {useEffect,useState } from 'react'
// import "./loginsignup.css"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { clearErrors,  register } from "../../actions/userAction";
import { useAlert } from "react-alert";

const Register = ({history}) => { 
  const dispatch = useDispatch();
  const alert = useAlert();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const registerSubmit = (e) => {
    e.preventDefault();
    if(password !== password2){
      alert.show("Passwords do not match");
    }
  else{
    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("password", password);
    dispatch(register(myForm));}
  };

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isAuthenticated) {
      alert.success("Registration Successful");
       history.push("/profile");
    }
  }, [dispatch, error, alert, isAuthenticated
    ,history, loading 
    ]);

  return (
    <div  className='aboutco'>
      {/* <div className="heading">
        <h1>Register </h1>
         </div> */}

         <div className="container">
         <div className="login-box">
        <h2>Register Here</h2>
       
        <form onSubmit={registerSubmit}>
        <div className="user-box">
            <input type="text" name="" required=""  value={name}
                    onChange={(e) => setName(e.target.value)}/>
            <label>Name </label>
          </div>
          <div className="user-box">
            <input type="Email" name="" required=""  value={email}
                    onChange={(e) => setEmail(e.target.value)}/>
            <label>Email</label>
          </div>
          <div className="user-box">
            <input type="password" name="" required=""  value={password}
                    onChange={(e) => setPassword(e.target.value)}/>
            <label>Password </label>
          </div>
          <div className="user-box">
            <input type="password" name="" required=""  value={password2}
                    onChange={(e) => setPassword2(e.target.value)}/>
            <label>confirm Password </label>
          </div>
         
         
          <button type='submit' style={{
            border:"none",
            outline:0,
            backgroundColor:"transparent",
            fontSize:"1.5rem",
            fontWeight:"bold",
          }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </button>
          
          
       

        </form>

       
        </div>
        
        </div>
        <div className="togglediv">
          <p>Already have an Account</p>
      <Link to="/login"><button >Login </button></Link>  
        </div>
       
        </div>
  )
}

export default Register
