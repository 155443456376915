import React from 'react'
import "./footer.css"
import { Link } from 'react-router-dom'
import  {FaFacebookSquare, FaInstagram, FaTwitter} from "react-icons/fa"

const Footer = () => {
  return (
    <>
      <div className="footer">
          <div className="navicons">
         <a href="https://www.facebook.com/dealinghand" target="_blank" ><FaFacebookSquare className='fitem'/></a> 
         <a href="http://www.twitter.com/@dealinghand2" target="_blank"> <FaTwitter className='fitem'/></a>
        <a href="http://www.instagram.com/dealinghand2" target="_blank"> <FaInstagram className='fitem'/></a>
         
          </div>
          <h3 className="recent">Free Advertising Website</h3>
          <div className="links">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact us</Link>
            <Link to="/Login">Login</Link>
            <Link to="/register">register</Link>
            <Link to="/sitemap.html">Sitemap</Link>
            {/* <a href="">link</a>
            <a href="">link</a>
            <a href="">link</a> */}
          </div>
          <div className="mainfooter">
            All Rights Reserved Free  Advertisement Company<span>@DealingHand</span>
          </div>
         
      </div>
    </>
  )
}

export default Footer
