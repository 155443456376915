import React,{useState,useEffect} from 'react'
import "./loginsignup.css"
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, forgotPassword } from "../../actions/userAction";
import { useAlert } from "react-alert";
import { JellyfishSpinner } from "react-spinners-kit";
// import MetaData from "../layout/MetaData";

import { Link } from 'react-router-dom'

const Login = () => {const dispatch = useDispatch();
  const alert = useAlert();

  const { error, message, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [email, setEmail] = useState("");

  const forgotPasswordSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("email", email);
    dispatch(forgotPassword(myForm));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (message) {
      alert.success(message);
    }
  }, [dispatch, error, alert, message]);
  return (<> {loading&&<div className="loadercon">
  <JellyfishSpinner />
  </div>}
  {!loading&&<div  className='aboutco'>
      {/* <div className="heading">
        <h1>Forget password</h1>
         </div> */}

         <div className="container">
         <div className="login-box">
        <h2>Have you forgetten password?</h2>
       
        <form   onSubmit={forgotPasswordSubmit}>
         
          <div className="user-box">
            <input type="Email" name="" required="" value={email}
                    onChange={(e) => setEmail(e.target.value)}/>
            <label>Enter your Email</label>
          </div>
         
         
         
          <button  type="submit"  style={{
            border:"none",
            outline:0,
            backgroundColor:"transparent",
            fontSize:"1.5rem",
            fontWeight:"bold",
          }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </button>
         
          
       

        </form>

       
        </div>
        
        </div>
        <div className="togglediv">
         
       <Link to="/register"><button >Register  </button></Link> 
       <Link to="/login"><button >Login  </button></Link> 
        </div>
       
        </div>}
        </>
  )
}

export default Login
