import axios from "axios";
import {storage } from "./firebase";
import {ref,uploadBytes,getDownloadURL} from "@firebase/storage";

import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_REQUEST,
  ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_FAIL,
  USER_PRODUCT_REQUEST,
  USER_PRODUCT_SUCCESS,
  USER_PRODUCT_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_FAIL,
  ALL_REVIEW_REQUEST,
  ALL_REVIEW_SUCCESS,
  ALL_REVIEW_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  CLEAR_ERRORS,
} from "../constants/productConstants";

const mainlink="http://localhost:4000"; 
// Get All Products
export const getProduct =
  (keyword = "", currentPage = 1,  category="",city, provience,country) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_PRODUCT_REQUEST });

     let link;
if(city&&provience&&country){
  link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&category=${category}&city=${city}`;
}
else if(country&&provience){
  link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&category=${category}&provience=${provience}`;
}
else if(country){
  link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&category=${category}&country=${country}`;}
else if(city&&provience){
  link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&category=${category}&city=${city}`;
}
      else if (city) {
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&city=${city}&category=${category}`;
      }
      else if (provience) {
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&provience=${provience}&category=${category}`;
      }
      else { 
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&category=${category}`;}
        const config = {
          headers: { "Content-Type": "application/json"},
        };
      const { data } = await axios.get(`${link}`,config);


      dispatch({
        type: ALL_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCT_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// Get All Products For Admin
export const getAdminProduct = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_PRODUCT_REQUEST });

    const { data } = await axios.get("/api/v1/admin/products");

    dispatch({
      type: ADMIN_PRODUCT_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};
// Get All user product For user
export const getUserProduct = (userid) => async (dispatch) => {
  try {
    dispatch({ type: USER_PRODUCT_REQUEST });

    const { data } = await axios.get(`/api/v1/products/${userid}`);

    dispatch({
      type: USER_PRODUCT_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: USER_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create Product
let images=[];
// const upload=(files)=> {
//   for(let i=0;i<files.length;i++){
//   if(!files){console.log("no file");return}
//   const storageRef=ref(storage,`images/${files[i].name}`);
//   uploadBytes(storageRef, files[i]).then((snapshot) => {
//     console.log('Uploaded a blob or file!');
//   });
//   getDownloadURL(storageRef)
// .then((url) => {console.log(url);
// images.push(url);})
  
// }}
export const createProduct = (productData,files) => async (dispatch) => {

 
      
      
  try {
    dispatch({ type: NEW_PRODUCT_REQUEST });
    for(let i=0;i<files.length;i++){
      if(!files){console.log("no file");return}
      const storageRef=ref(storage,`images/${files[i].name}`);
     await uploadBytes(storageRef, files[i])
     let url= await  getDownloadURL(storageRef)
     
     images.push(url);
    }
    
   images.forEach((image) => {
    productData.append("images", image);
       console.log(image);
    });
    // productData.append("images",images);

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/product/new`,
      productData,
      
      config
    );
    images=[];

    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Product
export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(
      `/api/v1/admin/product/${id}`,
      productData,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/product/${id}`);

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Products Details
export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/product/${id}`);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// NEW REVIEW
export const newReview = (reviewData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_REVIEW_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(`/api/v1/review`, reviewData, config);

    dispatch({
      type: NEW_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: NEW_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All Reviews of a Product
export const getAllReviews = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALL_REVIEW_REQUEST });

    const { data } = await axios.get(`/api/v1/reviews?id=${id}`);

    dispatch({
      type: ALL_REVIEW_SUCCESS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch({
      type: ALL_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Review of a Product
export const deleteReviews = (reviewId, productId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_REVIEW_REQUEST });

    const { data } = await axios.delete(
      `/api/v1/reviews?id=${reviewId}&productId=${productId}`
    );

    dispatch({
      type: DELETE_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
