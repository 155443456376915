import React, { Fragment, useState, useEffect } from "react";
import "./UpdateProfile.css";

import { useDispatch, useSelector } from "react-redux";
import { clearErrors, updateProfile, loadUser } from "../../actions/userAction";
import { useAlert } from "react-alert";
import { UPDATE_PROFILE_RESET } from "../../constants/userConstants";
import MetaData from "../layout/MetaData";
import {JellyfishSpinner} from "react-spinners-kit";

const UpdateProfile = ({ history }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { user } = useSelector((state) => state.user);
  const { error, isUpdated, loading } = useSelector((state) => state.profile);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  

  const updateProfileSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
   
    dispatch(updateProfile(myForm));
  };

  
  

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
     
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Profile Updated Successfully");
      dispatch(loadUser());

      history.push("/profile");

      dispatch({
        type: UPDATE_PROFILE_RESET,
      });
    }
  }, [dispatch, error, alert, history, user, isUpdated]);
  return (
    <>
    {loading&&<div className="loadercon">
        <JellyfishSpinner />
        </div>}
    {!loading&& <div  className='aboutco'>
          <MetaData title="Update your Profile"  />
      {/* <div className="heading">
        <h1>Login</h1>
         </div> */}

         <div className="container">
         <div className="login-box">
        <h2>Update Your Profile </h2>
       
        <form onSubmit={updateProfileSubmit}>
         
          
          <div className="user-box">
          <input
                    type="text"
                    placeholder="Name"
                    required
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
            <label> Name</label>
          </div>
          <div className="user-box">
          <input
                    type="email"
                    placeholder="Email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
            <label> Email</label>
          </div>
         
         
         
          <button type='submit' style={{
            border:"none",
            outline:0,
            backgroundColor:"transparent",
            fontSize:"1.5rem",
            fontWeight:"bold",
          }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            update
          </button>
     
          
       

        </form>
       

       
        </div>
        
        </div>
      
       
        </div>}
        
        </>
  );
};

export default UpdateProfile;
