import React from 'react'
import "./contact.css"
import MetaData from '../layout/MetaData'
import { useForm, ValidationError } from '@formspree/react';
import { useAlert } from "react-alert";

 
  
// import  {FaFacebookSquare, FaInstagram, FaTwitter, FaWhatsapp} from "react-icons/fa"

const Contact = () => {
  const alert = useAlert();
  const [state, handleSubmit] = useForm("xwkyayza");
  if (state.succeeded) {
      alert.success("Thanks for reaching out! We will get back to you soon!");}
  return (
    <>
    <MetaData title="Contact Page"  />
         <div  className='aboutco'>
      <div className="heading">
        <h1>Contact here</h1>
         </div>

         <div className="container">
         <div className="login-box">
        <h2>Contact us for any query</h2>
       
        <form onSubmit={handleSubmit}>
 
          <div className="user-box">
            <input type="text" name="name" required="true"/>
            <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
            <label>Name </label>
          </div>
          <div className="user-box">
            <input type="Email" name="email" required="true"/>
            <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
            <label>Email</label>
          </div>
          <div className="user-box">
            <textarea rows="10" name="message" required="true" className='text'/>
            <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
            <label>Enter your Message Here</label>
          </div>
         
          <button type="submit" disabled={state.submitting}
           style={{
            border:"none",
            outline:0,
            backgroundColor:"transparent",
            fontSize:"2rem",
            fontWeight:"bold",
          }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </button>

        </form>
       
    </div>
    </div>


     </div>
      
    </>
  )
}

export default Contact
