import React, { useState,useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ProductDetails.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Images = ({Imagelist}) => {
  const [imagelist, setimagelist] = useState(null);
  useEffect(() => {
    setimagelist(Imagelist);
  }, [Imagelist]);
  return (
    <>
         <Swiper
        effect={"flip"}
        grabCursor={true}
        pagination={true}
        navigation={true}
        modules={[EffectFlip, Pagination, Navigation]}
        className="mySwiper"
      >
        
        {/* <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-3.jpg"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-4.jpg"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-5.jpg"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
        </SwiperSlide> */}
      {
      imagelist &&
  imagelist.map((image,index)=>{return <SwiperSlide key={index}>
    <img src={image} alt={image}  />
  </SwiperSlide>})}
      </Swiper>
      
      </>
  )
}

export default Images
