export const Mobileandtech = ["LapTop","Mobile","Camera","Headphone","Accessories" ,"Tablets"]
export const Vehicles = ["Car","Bike","Spare Parts","cars Accessories","Boats","Rikshaw & Chingchi","Truck","Bus","Bikes" ] 
export const Homeandfurniture = ["All","Furniture","Home Appliances","Kitchen Appliances"  ]
export const health = ["All","Skin Care","Hair Care",  ]
export const Sportsandfitness = ["All","Cycling","Running","Swimming",  ]
export const Books = ["Books","Stationary","Musical Instruments","Gym and fitness"  ]
export const Animals= ["Dog","Cat","Fish","Bird","Hens","live Stock","Other Animals"]
export const Kids=["Toys","Kids Bikes","Kids Clothing","Kid Accessories","Swings and Slides" ]
export const Electronicsandhomeappliances = ["TV","Cameras","Generators & UPS","Kitchen Appliances","Refrigerator","Washing Machine"  ]
export const jobs = ["Full Time","Part Time","Internship","Accounting","Business","Marketing","Sales","Admin","HR","Freelance","Online" ,"IT","Accounting","Business","Marketing","Sales","Admin","HR" ]

export const Realestate = ["Flat","House","Plot","Commercial",  ]
export const Business=["All","Accounting","Business","Marketing","Sales","Admin","HR"  ]
export const Mobile=["Tablets","Accessories","Mobile Phones"]
export const Property=["Land ","Flat","House","Plot","shops","Commercial",  ]
export const Propertyforrent=["Land","Rooms","Flat","House","Plot","Guest House","Commercial" ]
export const fashionandbeauty=["Clothes","Shoes","Accessories","Jewellery","Make Up","Watches","Other Fashion"]
export const furniture=["Sofa","Beds","Dining","Living","Office","Garden","Carpets","Curtains" ]
export const services=["Cleaning","Beauty","Education","Travel & Visa","Drivers ","Web Development","Event Services","Car Rental","Electronic Reoair" ]
export const Others=["Others"]
