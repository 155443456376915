
import { useEffect } from 'react';
import store from "./store";
import ProtectedRoute from "./components/Route/ProtectedRoute";
import { loadUser } from "./actions/userAction";
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Hero from './components/hero/Hero';  
import Hero1 from './components/hero/Hero1';  
import Dashboard from "./components/Admin/Dashboard.js";
import ProductList from "./components/Admin/ProductList.js";
import Newadminproduct from "./components/Admin/Newadminproduct";
import UsersList from "./components/Admin/UsersList.js";
import UpdateProduct from "./components/Admin/UpdateProduct";
import UpdateUser from "./components/Admin/UpdateUser";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Login from './components/login/Login';
import Forget from './components/login/Forget';
import Pricing from './components/pricing/Pricing';
import NotFound from './components/notfound/NotFound';
import ResetPassword from './components/login/ResetPassword';
import Register from './components/register/Register';
import Profile from './components/account/Profile';
import ProductDetails from './components/product/ProductDetails';
import UpdateProfile from './components/login/UpdateProfile';
import UpdatePassword from './components/login/UpdatePassword';
import NewProduct from "./components/Admin/NewProduct";
import Userproductlist from "./components/Admin/Userproductlist";
import Updateuserproduct from "./components/Admin/Updateuserproduct";
import sitemap from './components/sitemap/Sitemap';
import Tc from './components/termsandconditions/TAC';
function App() {
  useEffect(() => {
    
    store.dispatch(loadUser());

    // getStripeApiKey();
  }, []);
  return (
   <Router>
  <Header/>
  
  

 



<Switch>
  <Route exact path='/' component={Hero}/>
  <Route exact path='/home' component={Hero}/>
  <Route exact path='/advertisedcategory' component={Hero1}/>
  <Route exact path='/about' component={About}/>
  <Route exact path='/Pricing' component={Pricing}/>
  <Route exact path='/contact' component={Contact}/>
  <Route exact path='/login'  component={Login}/>
  <Route exact path='/forgetpassword'  component={Forget}/>
  <Route exact path='/register'  component={Register}/>
  <Route exact path='/termsandconditions'  component={Tc}/>
  <Route exact path='/sitemap.html'  component={sitemap}/>

  
  <Route exact path="/passwordreset/:token" component={ResetPassword} />
  <Route exact path='/createad'  component={NewProduct}/>
  <ProtectedRoute exact path='/user/ads'  component={Userproductlist}/>
  {/* <Route exact path='/profile'  component={Profile}/> */}
  <Route exact path='/ad/:id'  component={ProductDetails}/>
  <ProtectedRoute exact path="/profile" component={Profile} />
  <ProtectedRoute exact path="/editprofile" component={UpdateProfile} />
  <ProtectedRoute exact path="/updatepassword" component={UpdatePassword} />
  <ProtectedRoute exact path="/updateuserproduct/:id" component={Updateuserproduct} />
  {/* <Route path='/ad/:id'  component={<ProductDetails/>}/> */}
  <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/dashboard"
          component={Dashboard}
        />
  <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/products"
          component={ProductList}
        />
  <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/product"
          component={Newadminproduct}
        />
  <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/users"
          component={UsersList}
        />
         <ProtectedRoute
          exact
          path="/admin/product/:id"
          isAdmin={true}
          component={UpdateProduct}
        />
          <ProtectedRoute
          exact
          path="/admin/user/:id"
          isAdmin={true}
          component={UpdateUser}
        />
        <Route component={NotFound}/>
</Switch>
<Footer/>

  </Router>
  );
}

export default App;
