import React, { Fragment, useState, useEffect } from "react";
import "./UpdatePassword.css";
import { JellyfishSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, updatePassword } from "../../actions/userAction";
import { useAlert } from "react-alert";
import { UPDATE_PASSWORD_RESET } from "../../constants/userConstants";
import MetaData from "../layout/MetaData";

const UpdatePassword = ({ history }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, isUpdated, loading } = useSelector((state) => state.profile);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const updatePasswordSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("oldPassword", oldPassword);
    myForm.set("newPassword", newPassword);
    myForm.set("confirmPassword", confirmPassword);

    dispatch(updatePassword(myForm));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Profile Updated Successfully");

      history.push("/profile");

      dispatch({
        type: UPDATE_PASSWORD_RESET,
      });
    }
  }, [dispatch, error, alert, history, isUpdated]);

  return (<>
    {loading && <div className="loadercon">
        <JellyfishSpinner />
        </div>}
    {!loading&& <div  className='aboutco'>
          <MetaData title="Password Update"  />
      {/* <div className="heading">
        <h1>Login</h1>
         </div> */}

         <div className="container">
         <div className="login-box">
        <h2>Update your Password</h2>
       
        <form onSubmit={updatePasswordSubmit}>
         
         
          <div className="user-box">
            <input type="Password" name="" required=""  value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}/>
            <label>Old Password</label>
          </div>
          <div className="user-box">
            <input type="Password" name="" required=""  value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}/>
            <label>New Password</label>
          </div>
          <div className="user-box">
            <input type="Password" name="" required=""  value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}/>
            <label>Confirm New Password</label>
          </div>
         
         
          <button type='submit' style={{
            border:"none",
            outline:0,
            backgroundColor:"transparent",
            fontSize:"1.5rem",
            fontWeight:"bold",
          }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </button>
     
          
       

        </form>
        

       
        </div>
        
        </div>
       
       
        </div>}
        
        </>
  );
};

export default UpdatePassword;
