import React from 'react'
import "./product.css";
import { Link } from 'react-router-dom';




export const Product = ({product}) => {
  
  return (<>
    <Link className="productCard" to={`/ad/${product._id}`}>
    <div className='productcont'>
      <div className="image">
      
   {product.images&&<img src={product.images[0]} alt="" />}
      </div>
      <div className="productcontent">
      <div className="name">{product.name} </div>
      <div className="price">Rs.{product.price}</div>
      <div className="locationcon">{product.city},{product.provience},{product.country} </div>
    </div>
   

    </div>
    </Link>
  
    </>
  )
}
export default Product
