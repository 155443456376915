import React from 'react'
import { Link } from 'react-router-dom'
import "./sitemap.css"
import {Mobile,Vehicles,Property,Propertyforrent,Animals,Kids,Electronicsandhomeappliances,fashionandbeauty,furniture,
  jobs,services,Sportsandfitness,Books} from "../hero/category.js"
const links=["home","about" ,"contact","login","register" ,"Pricing","dashboard"]
const categoriesList=[...Mobile, ...Property , ...Vehicles , ...Propertyforrent, ...Animals,...Kids,...Electronicsandhomeappliances,...fashionandbeauty,...furniture,
  ...jobs,...services,...Sportsandfitness,...Books]

const sitemap = () => {
  return (
    <div className='sitemap'>
    {
      links.map((link)=><li key={link}><Link  to={`/${link}`}> {link} </Link> </li>)
    }
    {
      categoriesList.map((link)=><li key={link}><Link  to={`/?advertisement_category=${link}`}> {link} </Link> </li>)
    }
       
    </div>
  )
}

export default sitemap
