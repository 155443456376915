import React, { Fragment, useEffect, useState, useRef } from "react";
import "./newProduct.css";
import PublicIcon from '@material-ui/icons/Public';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, createProduct } from "../../actions/productAction";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../layout/MetaData";
import HomeIcon from '@material-ui/icons/Home';
import {Mobile,Vehicles,Property,Propertyforrent,Animals,Kids,Electronicsandhomeappliances,fashionandbeauty,furniture,
  jobs,services,Sportsandfitness,Books,Others} from "./category.js"
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DescriptionIcon from "@material-ui/icons/Description";
import { JellyfishSpinner } from 'react-spinners-kit';
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AutorenewIcon from '@material-ui/icons/Autorenew';
// import CameraRearIcon from '@material-ui/icons-material/CameraRear';

import { NEW_PRODUCT_RESET } from "../../constants/productConstants";

const NewProduct = ({ history }) => {
  const fileInput=useRef(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const { user ,isAuthenticated } = useSelector(
    (state) => state.user
  );
  const { loading, error, success } = useSelector((state) => state.newProduct);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [mcategory, setmcategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
 const [provience,setProvience]=useState(null)
 const [city , setCity]=useState(null)
 const [condition,setCondition]=useState(null)
  // const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [number, setNumber] = useState(null);
  const [wnumber, setwNumber] = useState(null);
  const[clist,setClist]=useState(null);
  const  [data,setData]=useState(null);
  const [pl,setPl]=useState(null);
  const [cll,setCll]=useState(null);
  const [statel,setStatel]=useState(null);
  const [country,setCountry]=useState(null);

  const categories = [
   "Mobile","Vehicles","Property for Sale", "Property for Rent", "Electronics & Home Appliances", "Animals", "Kids", "Furniture", "Books,Sports & Hobbies", "Fashion & Beauty", "Services", "Jobs","Sports & Fitness","Others"
   
  ];
  useEffect(() => {
  


    axios.get("https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json")
    .then((res)=>{setData(res.data)
      const cl=[...new Set(res.data.map(item => item.country))];
     
      cl.sort();
      setClist(cl);
      
    })
    .catch(error => alert.error(error));
    
      },[alert]);
    
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    console.log(isAuthenticated);
  if(isAuthenticated===false){
    
    history.push("/login")
  alert.error("Please login to Post your Ad")}

    if (success) {
      alert.success("Product Created Successfully");

      history.push("/");
      dispatch({ type: NEW_PRODUCT_RESET });  
    }
  }, [dispatch, alert, error, history, success,isAuthenticated]);

  const createProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("username", user.name);
    myForm.set("userid", user._id); 
    myForm.set("price", price);
    myForm.set("username", user.name);
    myForm.set("description", description);
    myForm.set("condition", condition);
    myForm.set("city", city);
    myForm.set("provience", provience);
    myForm.set("category", [subcategory,mcategory]);
    myForm.set("number", number);
    myForm.set("wnumber", wnumber);
    myForm.set("country", country);
    if(address){
      myForm.set("address", address);
    }
//  let image=[];
let imagefile=fileInput.current.files;

//     for (let i = 0; i < fileInput.current.files.length; i++) {
//       myForm.append("images", fileInput.current.files[i]);
//       image.push(fileInput.current.files[i])
//     }
    // images.forEach((image) => {
    //   myForm.append("images", image);
    
    // });
    dispatch(createProduct(myForm,imagefile));
  };

  const createProductImagesChange = (e) => {
    const files = Array.from(e.target.files);

    // setImages([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
          // setImages((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };  

  return (
    <Fragment>
      <MetaData title="Create an ad" />
      {loading&&<div className="noproduct">
        <JellyfishSpinner color="pink" />
        </div>}
    {!loading&& 
         <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createProductSubmitHandler}
          >
            <h1>Create an AD</h1>

            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="Product Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Price"
                required
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>

            <div>
              <DescriptionIcon />

              <textarea
                placeholder="Product Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>

            <div>
              <AccountTreeIcon />
              <select  onChange={(e) => {setmcategory(e.target.value);}}>
                <option value="">Choose main Category</option>
                {categories.map((cate) => (
                  <option key={cate} value={cate}>
                    {cate}
                  </option>
                ))}
              </select>
            </div>
          {mcategory&&<div>
              <AccountTreeIcon />
              <select onChange={(e) => {setSubcategory(e.target.value)}}>
                <option value="">Choose sub Category</option>
                {mcategory==="Mobile"?Mobile.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                {mcategory==="Kids"?Kids.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                {mcategory==="Vehicles"?Vehicles.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                {mcategory==="Property for Sale"?Property.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                {mcategory==="Property for Rent"?Propertyforrent.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                
                {mcategory==="Animals"?Animals.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                {mcategory==="Electronics & Home Appliances"?Electronicsandhomeappliances.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
        {mcategory==="Fashion & Beauty"?fashionandbeauty.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
        {mcategory==="Jobs"?jobs.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
        {mcategory==="Furniture"?furniture.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
        {mcategory==="Sports & Fitness"?Sportsandfitness.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                
        {mcategory==="Books,Sports & Hobbies"?Books.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                
        {mcategory==="Services"?services.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                
        {mcategory==="Others"?Others.map((subcatname,index)=>{
          return <option  key={index} value={subcatname}>{subcatname}</option>
       }):null}
                
              </select>
            </div>}
            <div>
           <PublicIcon/>
            <select  name="" id=""  onChange={(e)=>{
       e.target.value==="null"?setCountry(null):setCountry(e.target.value);
      
      
      let  statesz=data.filter(item=>item.country===e.target.value);
       let states1ists= [...new Set(statesz.map(item=>item.subcountry))];
       setStatel(statesz);
        let states=states1ists.sort();
       setPl(states);
  
   
       
         }}>
              <option selected={(country==="null")?true:false} value="null">Select Country</option>
              {clist&&clist.map((item,index)=>{
                return <option key={index} selected={(country===item.name)?true:false} value={item}>{item}</option>
              })}
         </select>
            </div>
            {country&&<div>
              <select name="" id=""  onChange={(e)=>{
       e.target.value==="null"?setProvience(null):setProvience(e.target.value);

      
       let cities=statel.filter(item=>item.subcountry===e.target.value);
       setCll(cities);
      //   let cities1= [... new Set(cities.map(item=>item.city))];
    
       
         }}>
        <option selected={(provience==="null")?true:false} value="null">Select State</option>
        {pl.map((item,index)=>{
          return <option key={index} selected={(provience===item)?true:false} value={item}>{item}</option>
        })}
      
      </select>
            </div>}
            {provience&&<div>
              <select name="" id="" onChange={(e)=>{
       e.target.value==="null"?setCity(null):setCity(e.target.value);
     
       
         }}>
           <option selected={(city==="null")?true:false} value="null">Select City</option>
        {cll.map((item,index)=>{
          return <option key={index} selected={(city===item.name)?true:false} value={item.name}>{item.name}</option>
        })}




      
         
  
      </select>
            </div>}
            <div>
            <AutorenewIcon />
              <select name="" id="" onChange={(e)=>setCondition(e.target.value)}>
                <option value="">Select Condition</option>
                <option value="New">New</option>
                <option value="Used">Used</option>
              </select>
            </div>
            <div>
              <HomeIcon />
              <input
                type="text"
                placeholder="Address(Optional)"
                required={false}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div>
              <PhoneForwardedIcon />
              <input
                type="phone"
                placeholder="Phone Number"
                required
                min-length="9"
                max-length="13"
                onChange={(e) => setNumber(e.target.value)}
              />  
            </div>
            <div>
              <WhatsAppIcon />
              <input
                type="phone"
                placeholder="Whatsapp Number"
                required
                min-length="9"
                max-length="13"
                onChange={(e) => setwNumber(e.target.value)}
              />  
            </div>
  
            {/* <div>
              <StorageIcon />
              <input
                type="number"
                placeholder="Stock"
                required
                onChange={(e) => setStock(e.target.value)}
              />
            </div> */}

            <div id="createProductFormFile">
           
              <input
                type="file"
                name="avatar"
                required={true}
                ref={fileInput}
                accept="image/*"
                onChange={createProductImagesChange}
                multiple
              />
            </div>

            <div id="createProductFormImage">
              {imagesPreview.map((image, index) => (
                <img key={index} src={image} alt="Product Preview" />
              ))}
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>}
      
    </Fragment>
  );
};

export default NewProduct;
