import React, {useState } from 'react'
import  "./header.css"
import { FaUser} from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import {GiCrossedBones} from "react-icons/gi"
import logoimg from "../../Assests/updated.png"
import {Link } from "react-router-dom";
import { useSelector } from "react-redux";


const Header = () => {
  const [toggle,setToggle]=useState(false)
 

    
  const { isAuthenticated } = useSelector(
    (state) => state.user
  );

   
   
  return ( 
   
  
   <header className='header'>
      <Link to="/"> <div className="logo"><img src={logoimg} alt="logo" /></div></Link>
       <div className={toggle? "navcontainer navactive":"navcontainer"}>
          <Link to="/about" > <div className="item">About Us</div></Link>
          <Link to="/pricing"> <div className="item">Pricing Plans</div></Link>
           <Link to="/contact" > <div className="item">Contact Us</div></Link>
           <Link to="/createad" >   <div className="item">Advertise Here</div></Link>
       </div>
       <div className="logincontainer"  >
          {isAuthenticated?<Link to="/profile"><FaUser className='user' title='User'/></Link>:<Link to="/login"><FaUser className='user ' /></Link>}
         {toggle ?<GiCrossedBones className='user bar'  onClick={()=>{setToggle(!toggle)}}/>:<FaBars className='user bar'  onClick={()=>{setToggle(!toggle)}}/> }   
            
       </div>


   </header>
  )
}

export default Header 
