import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  clearErrors,
  updateProduct,
  getProductDetails,
} from "../../actions/productAction";
import { useAlert } from "react-alert";
import {Mobile,Vehicles,Property,Propertyforrent,Animals,Kids,Electronicsandhomeappliances,fashionandbeauty,furniture,
  jobs,services,Sportsandfitness,Books} from "./category.js";
import { Button } from "@material-ui/core";

import MetaData from "../layout/MetaData";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DescriptionIcon from "@material-ui/icons/Description";
// import StorageIcon from "@material-ui/icons/Storage";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SideBar from "./Sidebar";

import { UPDATE_PRODUCT_RESET } from "../../constants/productConstants";

const UpdateProduct = ({ history, match }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, product } = useSelector((state) => state.productDetails);

  const {
    loading,
    error: updateError,
    isUpdated,
  } = useSelector((state) => state.product);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [address, setAddress] = useState(null);
  const [description, setDescription] = useState("");
  const [mcategory, setmcategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
 const [provience,setProvience]=useState(null)
 const [city , setCity]=useState(null)
 const [condition,setCondition]=useState(null)
  // const [images, setImages] = useState([]);
  // const [imagesPreview, setImagesPreview] = useState([]);
  const [number, setNumber] = useState(null);
  const [wnumber, setwNumber] = useState(null);
  const[clist,setClist]=useState(null);
  const  [data,setData]=useState(null);
  const [pl,setPl]=useState(null);
  const [cll,setCll]=useState(null);
  const [statel,setStatel]=useState(null);
  const [country,setCountry]=useState(null);
  const [ccc,setCcc]=useState(null);
  const [ddd,setDdd]=useState(null);
  const [active,setActive]=useState(null);
  
  const categories = [
    "Mobile","Vehicles","Property for Sale", "Property for Rent", "Electronics & Home Appliances", "Animals", "Kids", "Furniture", "Books,Sports & Hobbies", "Fashion & Beauty", "Services", "Jobs","Sports & Fitness"
    
   ];
 

  const productId = match.params.id;

  useEffect(() => {
  


    axios.get("https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json")
    .then((res)=>{setData(res.data)
      const cl=[...new Set(res.data.map(item => item.country))];
      console.log(cl);
      cl.sort();
      setClist(cl);
      
    })
    .catch(error => console.log('error', error));
    
      }, []);
  useEffect(() => {
    if (product && product._id !== productId) {
      dispatch(getProductDetails(productId));
    } else {
      setName(product.name);
      setDescription(product.description);
      setPrice(product.price);
     setmcategory(product.category[1]);
     setSubcategory(product.category[0]);
     setProvience(product.provience);
     setCity(product.city);
     setCountry(product.country);
     setNumber(product.number);
     setwNumber(product.wnumber);
     setCondition(product.condition);
      // setImages(product.images);
      // setImagesPreview(product.images);
      setActive(product.active);
      if(product.address){
      setAddress(product.address);}
      // setOldImages(product.images);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Product Updated Successfully");
      history.push("/admin/products");
      dispatch({ type: UPDATE_PRODUCT_RESET });
    }
  }, [
    dispatch,
    alert,
    error,
    history,
    isUpdated,
    productId,
    product,
    updateError,
  ]);

  const updateProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();
    myForm.set("name", name);

    
    myForm.set("price", price);
   
    myForm.set("description", description);
    myForm.set("condition", condition);
    myForm.set("city", city);
    myForm.set("provience", provience);
    myForm.set("category", [subcategory,mcategory]);
    myForm.set("number", number);
    myForm.set("wnumber", wnumber);
    myForm.set("country", country);
    myForm.set("active", active);

    if(address){
      myForm.set("address", address);
    }

    // images.forEach((image) => {
    //   myForm.append("images", image);
    // });
    dispatch(updateProduct(productId, myForm));
  };

  // const updateProductImagesChange = (e) => {
  //   const files = Array.from(e.target.files);

  //   setImages([]);
  //   setImagesPreview([]);
  //   setOldImages([]);

  //   files.forEach((file) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setImagesPreview((old) => [...old, reader.result]);
  //         setImages((old) => [...old, reader.result]);
  //       }
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // };

  return (
    <Fragment>
      <MetaData title="Update Product" />
      <div className="dashboard">
        <SideBar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={updateProductSubmitHandler}
          >
            <h1>Update your AD</h1>

<div>
  <SpellcheckIcon />
  <input
    type="text"
    placeholder="Product Name"
    required
    value={name}
    onChange={(e) => setName(e.target.value)}  
  />
</div>
<div>
  <AttachMoneyIcon />
  <input
    type="number"
    placeholder="Price"
    required
    value={price}
    onChange={(e) => setPrice(e.target.value)}
  />
</div>

<div>
  <DescriptionIcon />

  <textarea
    placeholder="Product Description"
    value={description}
    onChange={(e) => setDescription(e.target.value)}
    cols="30"
    rows="1"
  ></textarea>
</div>

<div>
  <AccountTreeIcon />
  <select  value={mcategory} onChange={(e) => {setmcategory(e.target.value); }}>
    <option value="" selected={mcategory===""?true:false}>Choose main Category</option>
    {categories.map((cate) => (
      <option selected={mcategory===cate?true:false} key={cate} value={cate}>
        {cate}
      </option>
    ))}
  </select>
</div>
{mcategory&&<div>
  <AccountTreeIcon />
  <select value={mcategory} onChange={(e) => {setSubcategory(e.target.value);console.log(subcategory)}}>
    <option value="">Choose sub Category</option>
    {mcategory==="Mobile"?Mobile.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    {mcategory==="Kids"?Kids.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    {mcategory==="Vehicles"?Vehicles.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    {mcategory==="Property for Sale"?Property.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    {mcategory==="Property for Rent"?Propertyforrent.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    
    {mcategory==="Animals"?Animals.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    {mcategory==="Electronics & Home Appliances"?Electronicsandhomeappliances.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
{mcategory==="Fashion & Beauty"?fashionandbeauty.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
{mcategory==="Jobs"?jobs.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
{mcategory==="Furniture"?furniture.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
{mcategory==="Sports & Fitness"?Sportsandfitness.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    
{mcategory==="Books,Sports & Hobbies"?Books.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    
{mcategory==="Services"?services.map((subcatname,index)=>{
return <option  key={index} value={subcatname}>{subcatname}</option>
}):null}
    
  </select>
</div>}
<div>
            <select  name="" id=""  onChange={(e)=>{
       e.target.value==="null"?setCountry(null):setCountry(e.target.value);
       setCcc(e.target.value);
      let  statesz=data.filter(item=>item.country===e.target.value);
       let states1ists= [...new Set(statesz.map(item=>item.subcountry))];
       setStatel(statesz);
        let states=states1ists.sort();
       setPl(states);
       console.log(e.target.value);
   
       
         }}>
              <option selected={(country==="null")?true:false} value="null">Select Country</option>
              {clist&&clist.map((item,index)=>{
                return <option key={index} selected={(country===item.name)?true:false} value={item}>{item}</option>
              })}
         </select>
            </div>
{ccc&&<div>
              <select name="" id=""  onChange={(e)=>{
       e.target.value==="null"?setProvience(null):setProvience(e.target.value);

      
       let cities=statel.filter(item=>item.subcountry===e.target.value);
       setCll(cities);
      setDdd(e.target.value);       

      //   let cities1= [... new Set(cities.map(item=>item.city))];
       console.log(provience);
       
         }}>
        <option selected={(provience==="null")?true:false} value="null">Select State</option>
        {pl.map((item,index)=>{
          return <option key={index} selected={(provience===item)?true:false} value={item}>{item}</option>
        })}
      
      </select>
            </div>}
            {ddd&&<div>
              <select name="" id="" onChange={(e)=>{
       e.target.value==="null"?setCity(null):setCity(e.target.value);
       console.log(e.target.value);
       
       console.log(city)
       
         }}>
           <option selected={(city==="null")?true:false} value="null">Select City</option>
        {cll.map((item,index)=>{
          return <option key={index} selected={(city===item.name)?true:false} value={item.name}>{item.name}</option>
        })}

      </select>
            </div>}

<div>
  <select name="" id="" onChange={(e)=>setCondition(e.target.value)}>
    <option value="">Select Condition</option>
    <option selected={condition==="New"?true:false} value="New">New</option>
    <option selected={condition==="Used"?true:false} value="Used">Used</option>
  </select>
</div>
<div>
  <select name="" id="" onChange={(e)=>setActive(e.target.value)}>
  <option value="">Activate product</option>
    <option selected={active===true} value={true}>true</option>
    <option selected={active===false} value={false}>false</option>
  </select>
</div>
<div>
  <AttachMoneyIcon />
  <input
    type="ph"
    placeholder="Phone Number"
 value={number}
  min-length="10"
  max-length="12"
    onChange={(e) => setNumber(e.target.value)}
  />  
</div>
<div>
  <AttachMoneyIcon />
  <input
    type="ph"
    placeholder="Whatsapp Number"
  value={wnumber}
    min-length="10"
  max-length="12"
    onChange={(e) => setwNumber(e.target.value)}
  />  
</div>

{/* <div>
  <StorageIcon />
  <input
    type="number"
    placeholder="Stock"
    required
    onChange={(e) => setStock(e.target.value)}
  />
</div> */}

{/* <div id="createProductFormFile">
  <input
    type="file"
    name="avatar"
    accept="image/*"
    onChange={updateProductImagesChange}
    multiple
  />
</div>

<div id="createProductFormImage">
              {oldImages &&
                oldImages.map((image, index) => (
                  <img key={index} src={image.url} alt="Old Product Preview" />
                ))}
            </div>

            <div id="createProductFormImage">
              {imagesPreview.map((image, index) => (
                <img key={index} src={image} alt="Product Preview" />
              ))}
            </div> */}

<Button
  id="createProductBtn"
  type="submit"
  disabled={loading ? true : false}
>
  Update
</Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateProduct;
