import React, { Fragment, useEffect } from "react";
import { useSelector ,useDispatch } from "react-redux";
import MetaData from "../layout/MetaData";
// import Loader from "../layout/Loader/Loader";
import { Link , } from "react-router-dom";
import img from "../../Assests/new1.png"
import { JellyfishSpinner } from "react-spinners-kit";
import { logout } from "../../actions/userAction";
import "./Profile.css";
import {useAlert} from 'react-alert'


const Profile = ({ history }) => {
  
  const alert = useAlert()
  const dispatch = useDispatch();
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
  function logoutUser() {
    dispatch(logout());
   
    alert.success("Logout Successfully");
      
   }
 
 
  useEffect(() => {
    
    if (isAuthenticated===false) {
      history.push("/login");
     
    }
  }, [history, isAuthenticated, loading]);
  return (
    <Fragment>
      <MetaData title="Profile Page" />
      {loading ? ( <div className="loadercon">
        <JellyfishSpinner />
        </div>
      ) : (
        <Fragment>
        
          <div className="profileContainer">
            <div className="profile">
              <h1>My Profile</h1>
              
              <img src={img} alt={user.name} />
              <Link to="/editprofile">Edit Profile</Link>
               <Link to="updatepassword">Change Password</Link>
               <Link to="/user/ads">My Advertisements</Link>
               <Link to="/createad">Create an Advertisement </Link>
            </div>
            <div>
              <div >
                <h4>Full Name</h4>
                <p>{user.name}</p>
              </div>
              <div>
                <h4>Email</h4>
                <p>{user.email}</p>
              </div>
              <div>
                <h4>Joined On</h4>
                <p>{String(user.createdAt).substr(0, 10)}</p>
              </div>

              <div>
            
                
              </div>
              <div  >
            
             {user.role==="admin"&&<Link to="/admin/dashboard">Admin Panel</Link>}
             <button className="btnq" onClick={logoutUser} >LogOut</button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Profile;
