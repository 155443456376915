import React ,{useState ,useEffect} from 'react'
import "./loginsignup.css"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from "../../actions/userAction";
import { useAlert } from "react-alert";
import MetaData from '../layout/MetaData';
import { JellyfishSpinner } from 'react-spinners-kit';

const Login = ({history,location}) => {
  // const redirect = location.search ? location.search.split("=")[1] : "/account";
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const dispatch = useDispatch();
  const alert = useAlert();
  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(login(loginEmail, loginPassword));

  };
  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isAuthenticated) {
      history.push("/profile");
    }
  }, [dispatch, error, alert, isAuthenticated,  loading ,history]);


 
  return (<>
    {loading&&<div className="loadercon">
        <JellyfishSpinner />
        </div>}
    {!loading&& <div  className='aboutco'>
          <MetaData title="Login"  />
      {/* <div className="heading">
        <h1>Login</h1>
         </div> */}

         <div className="container">
         <div className="login-box">
        <h2>login here</h2>
       
        <form onSubmit={loginSubmit}>
         
          <div className="user-box">
            <input type="Email" name="" required=""   value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}/>
            <label>Email</label>
          </div>
          <div className="user-box">
            <input type="Password" name="" required=""  value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}/>
            <label>Password</label>
          </div>
         
         
          <button type='submit' style={{
            border:"none",
            outline:0,
            backgroundColor:"transparent",
            fontSize:"1.5rem",
            fontWeight:"bold",
          }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </button>
     
          
       

        </form>
        <Link to="/forgetpassword"> <p className='fcon'>Forget password?</p></Link> 

       
        </div>
        
        </div>
        <div className="togglediv">
          <p>Don't have an Account</p>
       <Link to="/register"><button className='asd'>Register  </button></Link> 
        </div>
       
        </div>}
        
        </>
  )
}

export default Login
