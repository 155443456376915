import React, { Fragment, useEffect, } from "react";
import Images from "./Images";

// Import Swiper styles


import "./ProductDetails.css";
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  getProductDetails,
  
} from "../../actions/productAction";
import { JellyfishSpinner } from "react-spinners-kit";

import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";




const ProductDetails = ({ match }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  console.log(product);

 
  
 

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());   // clear error
    }

   
    dispatch(getProductDetails(match.params.id));
    
  }, [dispatch, match.params.id, error, alert, loading]);

  return (
    <Fragment>
      {loading ? (<div className="noproduct" >
        <JellyfishSpinner color="pink" />
        </div>
      ) : (
        <Fragment >
           <MetaData title={`${product.name}, ${product.category}`} /> 
          
          
          <div className="pcontainer">
   
            <div className="adhead1">
          {product.country&&<div className="category">Country:{product.country}</div>}
          {product.category&&<div className="category">Category:{product.category}</div>}
        {  product.provience&&<div className="category">Provience:{product.provience}</div>}
        {  product.city&&<div className="category">City:{product.city}</div>}
            </div>
           <div className="maincontainer">
            <div className="pimage">
         {product&&<Images key={product._id} Imagelist={product.images}/>}
     

            </div>
            <div className="pcont">
            
              <div className="namecon">
              
               <h2> {product.name}</h2>
              <h4> {product.price}</h4>
              <h4>{product.city},{product.provience},{product.country}</h4>
              </div>
              
             
              {/* <h2>
              Adress of Product</h2>
              <h3>  {/* {product.address} */}
              {/* cxvcxvxcvxcvx</h3> */}
             
              
              <h1>Seller Description</h1>
              <div className="namecon">
              <h3>Seller Name</h3>
                <h3>{product.username}</h3> 
                 
              <h3>Seller's Number
               
                 </h3>
                 <h3>   {product.number}</h3>
              <h3>Seller's  Whatsapp </h3>
                <h3> {product.wnumber}</h3>
              {/* <h2>Seller's  Email </h2>
                <h3> {product.wnumber}myemail@gmail.com</h3> */}
                {/* <a href="mailto:someone@example.com">Send email</a> */}
               </div>
            </div>
           <div className="pdetail">
            <h1>Product Details</h1>
            <h2>Condition:{product.condition} </h2>
            <h2>Price:{product.price} </h2>
           {product.address&&<h2>Address:{product.address} </h2>}

            
           </div>
            </div>
            <div className="pdis">
            <h2>Product Description</h2>
              <h3>{product.description} 
                </h3>
            </div>

           
          </div>

        

          

             
            
            
         
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductDetails;
