import React from 'react'
import "./about.css"
import MetaData from "../layout/MetaData";
import { Link } from 'react-router-dom';

const About = () => {
  return (<>
    <MetaData title="About"  />
    <div  className='aboutco'>
      <div className="heading">
        <h1>About DealingHand</h1>

      </div>
      <div className="content">
        <h2 className='contenth1'> Introduction:</h2>
        <p>
        Basically, its website that hosts classified ads for things that can be bought and sold online. Using the
company's platform, customers can easily, safely, and conveniently find their ideal home, buy, or sell a
car, find a great job, sell things they no longer need, or strike a great deal on something they need. The
company's platform also helps thousands of entrepreneurs and businesses find their customers. It
allows anyone to purchase and sell easily and conveniently in their area.

        </p>
        <p>The idea behind this website to facilitates the listing and sale of products, the processing of orders, and
tracking of inventory and sales across several sales channels. You won't sell anything that you don't have
because orders from all your channels automatically flow to dealing hand.com and your inventory
quantity is updated across all marketplaces around you.
</p>
      <h2 className='contenth1'> How do we Operate:</h2>
      <p>
      Simple, user-focused dashboards and pages that show complex data from internet markets
Our email automation function makes it simple to offer top-notch customer support, expand sales
prospects, and promote more client feedback.
Build and monitor bundled/kitted inventories across several product marketplaces.
Set a sale price or percentage discount for a specific product on one or more marketplaces to boost
sales.

      </p>
      <p>
      The website is made especially for online service sales. Products are generally the goal of e-commerce
solutions. no longer. It is only intended for online service sales. Marketplaces are frequently used to sell
services. Dealinghand has the potential to function as a multivendor platform. Prices that are
determined hourly, daily, weekly, or even minutely, calendar availability, geolocation and geofencing,
purchasing procedures tailored to services, and a whole lot more. Profit from the full range of features
needed to successfully establish and run a global marketplace. Dealing Hand is the entrance to online
service sales since it allows for extensive customization and is simple to integrate with third-party
systems. utilized by thousands of platforms for the online sale of services.
      </p>
      <p>
      It's the ideal location to go if you want to organize your area or find goods that will give it a fresh look.
It's never felt better to let go; it almost compares to discovering a new best-loved thing. And we're
available to assist. We enable millions of individuals in Pakistan and around the world to buy and sell
nearly anything. Everybody has possessions they no longer use, never utilized, or have outgrown.
However, these priceless artefacts are still valuable. Our staff is constantly looking for fresh approaches
to make trading goods simpler.
      </p>
      <p>
      Your buying experience has now been considerably more engaging. You can search for and purchase
your preferred products at deainghand.com. The stuff that you no longer use can also be sold.
Additionally, you may send your favorite postings to friends and family via SMS, WhatsApp, Gmail, etc.
      </p>
      <h2 className='contenth1'> Simpler, Trustable and Verified

</h2>
      <p>The marketplace is the quickest, safest method to purchase and sell things locally. Find discounts on a
variety of amazing products in your area, like furniture, electronics, and autos, and buy from your other.
Send sellers a confidential message right away to discuss a price and a meeting time. View ratings,
badges, and transaction history on people's profiles. Simply taking a photo with your phone can help
you sell something you own. Become one of the trusted family members of users on the mobile
marketplace for area buyers and sellers.
</p>
      <p>
      Purchase and sell goods locally, or have products delivered from shops. Marketplace is an online
marketplace that links vendors and customers through interesting conversations and one-of-a-kind
products. There are no additional costs to list or purchase products if you have an active account.
Browse and purchase stuff, or list and sell goods to users all across the nation. You can communicate
with individuals through any means, whether you're buying or selling. Discover distinctive products,
from stylish clothing to home décor. 
      </p>





















         <div className='btn1con'>
         <Link to="/contact"> <button className='btn1'> Contact Us</button></Link>
        
         </div> 
      </div>


     </div>
     </>
  )
}

export default About
